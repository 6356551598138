/* A4 size and responsiveness functionality */

* {
    padding: 0;
    margin: 0;
    width: 100%;
    resize: none;
  }

  html,
  body,
  #container {
    height: 297mm;
  }

  @media print {
    @page {
      size: A4;
    }
  }

  #container {
    width: 210mm;
    height: 297mm;
    margin: 0 auto;
    font-size: 12pt;
    line-height: 1.5;
  }

  p {
    font-size: 10px;
  }

  /* Actual Designs */

  .schoolName {
    font-weight: bold;
    padding-top: 2%;
  }

  .p_heading {
    font-weight: bold;
    margin-top: -1%;
  }

  .inner_border {
    width: 100%;
  }
  .passport {
    border-radius: 50%;
  }

  .student_info {
    margin-top: -5%;
    font-size: 13px;
    font-weight: 500;
  }

  .subject {
    margin: auto;
  }

  .text-bold {
    font-weight: 600;
  }

  .hero {
    background-color: rgba(93, 219, 251, 0.901);
    background-image: linear-gradient(rgba(93, 219, 251, 0.901), white);
  }

  .school_name {
    text-shadow: 2px 2px #fff;
  }